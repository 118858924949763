import { TermAutosuggest } from '@classes/term-autosuggest.class';
import { MatDialog } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { SearchParamsService } from '../../../+search/search-params.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { MsaSettingsService } from '../../msa.settings.service';
import { Breakpoints } from '@classes/breakpoints.class';
import { SearchTrigger } from '@classes/search-trigger.class';
import { BvaService } from '../bva.service';
import { NotificationOverlayComponent } from '../../../notification-overlay';
import { Router } from '@angular/router';
import { Msa } from '@interfaces/msa.model';
import { WindowService } from '@services/window.service';
import { HttpParams } from '@angular/common/http';

interface NotificationButton {
  translation: string;
  method: string;
}

@Component({
  selector: 'app-bva-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class BvaSidebarComponent implements OnInit, OnDestroy {
  public enabled: boolean = false;
  public msa: Msa;
  public intendedProvider = null;
  public confirmModal = null;

  private procedureId = null;
  private subscriptions = new SubscriptionManager();
  private lastSearchParams: HttpParams;

  constructor(
    private msaSettingsService: MsaSettingsService,
    public breakpoints: Breakpoints,
    public searchParamsService: SearchParamsService,
    public searchTrigger: SearchTrigger,
    public bvaService: BvaService,
    public termAutosuggest: TermAutosuggest,
    public dialog: MatDialog,
    public router: Router,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    this.subscribeToMsaSso();
    this.getLastSearchParams();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public subscribeToMsaSso(): void {
    this.subscriptions.add(
      this.msaSettingsService
        .getMsaSso()
        .pipe(
          filter(
            (msaSso: Msa) =>
              !!(msaSso.sso && msaSso.msaId && msaSso.bvaEndpoint)
          )
        )
        .subscribe((msa) => {
          this.msa = msa;
          this.enabled = true;
          this.subscriptions.add(this.subscribeToSearchResults());
        })
    );
  }

  public subscribeToSearchResults(): Subscription {
    return this.bvaService.lastSearchResults
      .pipe(
        filter(
          (results) => results && !!this.lastSearchParams.get('procedure_id')
        )
      )
      .subscribe((lastSearchResults) => {
        this.bvaService
          .requestProviders()
          .pipe(take(1))
          .subscribe((searchResults) => {
            if (
              this.procedureHasChanged(lastSearchResults) &&
              this.resultsHaveChanged(searchResults) &&
              !this.bvaService.providersAreClean()
            ) {
              this.openConfirmationModal(true, searchResults);
            } else {
              this.setSearchResults(searchResults);
              this.procedureId = this.lastSearchParams.get('procedure_id');
            }
          });
      });
  }

  public intendedProvidersAreAvailable(): boolean {
    return !!(
      this.bvaService.results &&
      this.bvaService.results.providers &&
      this.bvaService.results._meta &&
      this.bvaService.results._meta.cost
    );
  }

  public clearIntendedProvider(): void {
    this.intendedProvider = null;
  }

  public sync(): void {
    this.bvaService.sync();
  }

  public newSearch(): void {
    this.openConfirmationModal();
  }

  public getButtons(resultsChanged: boolean): Array<NotificationButton> {
    return [
      {
        translation: resultsChanged ? 'bva_cancel_change' : 'bva_stay_on_page',
        method: resultsChanged ? 'goBack' : 'closeModal',
      },
      {
        translation: resultsChanged ? 'bva_change_procedure' : 'bva_continue',
        method: 'activateNewSearch',
      },
    ];
  }

  public getBodyText(resultsChanged: boolean): string {
    return resultsChanged
      ? 'bva_confirmation_modal_body_result_changed'
      : 'bva_confirmation_modal_body_result_not_changed';
  }

  public goBack(): void {
    this.confirmModal.close();
    this.windowService['history'].back();
  }

  private activateNewSearch(resultsChanged: boolean, results: any): void {
    this.clearIntendedProvider();
    this.bvaService.clearResults();
    this.bvaService.clearProviders();
    this.confirmModal.close();
    if (!resultsChanged) {
      this.termAutosuggest.activateHome();
    } else {
      this.bvaService.results = results;
    }
  }

  private resultsHaveChanged(res: any): boolean {
    return !!(
      this.bvaService.results.providers &&
      JSON.stringify(res) !== JSON.stringify(this.bvaService.results)
    );
  }

  private openConfirmationModal(resultsChanged?: boolean, results?: any): void {
    this.confirmModal = this.dialog.open(NotificationOverlayComponent, {
      width: '550px',
      data: {
        headline: '',
        body: this.getBodyText(resultsChanged),
        buttons: this.getButtons(resultsChanged),
        goBack: () => this.goBack(),
        closeModal: () => this.confirmModal.close(),
        activateNewSearch: () =>
          this.activateNewSearch(resultsChanged, results),
      },
    });
  }

  private procedureHasChanged(lastSearchResults: any): boolean {
    return lastSearchResults.searchParams?.procedureId !== this.procedureId;
  }

  private setSearchResults(search: any): void {
    const providers = this.bvaService.results.providers;
    if (search.providers && providers && providers.length > 0) {
      this.bvaService.results.providers.push(...search.providers);
      const meta = this.bvaService.results._meta;
      if (search._meta && meta && meta.length > 0) {
        this.bvaService.results._meta.push(...search._meta);
      }
    } else {
      this.bvaService.results = search;
    }
  }

  private getLastSearchParams(): void {
    this.subscriptions.add(
      this.searchParamsService.lastSearchParams.subscribe(
        (params: HttpParams) => (this.lastSearchParams = params)
      )
    );
  }
}
