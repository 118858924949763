<zelis-dialog data-cy="notification-overlay" class="px-4 pt-4">
  <mat-dialog-content>
    <div *ngIf="!data.noClose" class="absolute top-0 right-0">
      <app-close-button
        (closeCalled)="dialogRef.close()"
        data-cy="directions-overlay.close-button">
      </app-close-button>
    </div>

    <zelis-dialog-header
      [title]="data.headline | translate">
    </zelis-dialog-header>

    <div
      data-cy="notification-overlay.body"
      [innerHTML]="data.body | translate"
    ></div>

    <mat-dialog-actions class="justify-center text-center md:justify-end">
      <div class="submit-row">
        <zelis-button
          *ngIf="!data.buttons"
          (clickFn)="dialogRef.close()"
          (keydown.enter)="dialogRef.close()"
          variant="flat"
          class="view-profile"
          color="accent"
        >
          {{ 'app_notification_close' | translate }}
        </zelis-button>

        <!-- Custom behavior -->
        <div *ngIf="data.buttons" class="flex flex-col md:flex-row gap-2">
          <zelis-button
            *ngFor="let button of data.buttons; let i = index"
            [attr.data-cy]="'notification-overlay.custom-button-' + i"
            (clickFn)="data[button.method]()"
            variant="stroked"
            [ngClass]="{ 'external-link-indicator-icon': button.external_link }"
            color="accent"
          >
            {{ button.translation | translate }}
          </zelis-button>
        </div>
      </div>
    </mat-dialog-actions>
  </mat-dialog-content>
</zelis-dialog>
