<!-- Simple, Navigation and Multiple Selection List -->
<div *ngIf="multiple === true">
  <ng-container *ngTemplateOutlet="listContent"></ng-container>
</div>

<!-- Single Selection List -->
<mat-radio-group *ngIf="multiple === false">
  <ng-container *ngTemplateOutlet="listContent"></ng-container>
</mat-radio-group>

<!-- List Content -->
<ng-template #listContent>
  <ng-content></ng-content>
</ng-template>
