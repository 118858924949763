import { ActiveDescendantKeyManager, FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { ListItemComponent } from '@zelis/dls/list-item';
import { coerceBoolean } from 'coerce-property';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'zelis-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements AfterContentInit, OnDestroy {
  @HostBinding() class = 'zelis-dls';
  @HostBinding('attr.tabindex') get hostTabindex() {
    return this.type === 'simple' ? -1 : 0;
  }

  @Input() type: 'simple' | 'selection' | 'navigation' | 'action' = 'simple';
  @Input() @coerceBoolean multiple = true;
  @Input() @coerceBoolean standalone = false;

  @ContentChildren(ListItemComponent)
  private items!: QueryList<ListItemComponent>;

  private keyManager!:
    | ActiveDescendantKeyManager<ListItemComponent>
    | FocusKeyManager<ListItemComponent>;
  private destroy: Subject<void> = new Subject();

  @HostListener('keydown', ['$event'])
  onHostKeydown(event: KeyboardEvent) {
    this.onKeydown(event);
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    this.setActiveItemFromChildClick(event);
  }

  @HostListener('blur')
  onHostBlur() {
    this.deactivateItems();
  }

  constructor(public elementRef: ElementRef) {}

  ngAfterContentInit() {
    this.initItems();
    this.initKeyManager();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.keyManager.destroy();
  }

  public getMultiSelected(): { name: string; id: any; value: any }[] | null {
    if (this.type !== 'selection' || !this.multiple || !this.items) {
      return null;
    }
    return this.items
      .toArray()
      .filter(
        (item) =>
          item.type === 'selection' &&
          (item.multiple || item.slideToggle) &&
          !item.disabled &&
          item.getChecked()
      )
      .map((item) => ({ name: item.name, id: item.id, value: item.value }));
  }

  public getSingleSelected(): { name: string; id: any; value: any } | null {
    if (this.type !== 'selection' || this.multiple || !this.items) {
      return null;
    }
    return this.items
      .toArray()
      .filter(
        (item) =>
          item.type === 'selection' &&
          !item.multiple &&
          !item.disabled &&
          item.getChecked()
      )
      .map((item) => ({ name: item.name, id: item.id, value: item.value }))[0];
  }

  private onKeydown(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Enter') {
      this.keyManager.activeItem?.handleItemSelect(event);
      event.preventDefault();
    }
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      this.keyManager.onKeydown(event);
      event.preventDefault();
    }
  }

  private initItems() {
    this.items.changes
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        startWith(this.items),
        takeUntil(this.destroy)
      )
      .subscribe((items) => {
        items.forEach((item: ListItemComponent, index: number) => {
          this.setItemDefaults(item, index);
        });
        this.items.notifyOnChanges();
      });
  }

  private initKeyManager() {
    if (this.standalone) {
      this.keyManager = new FocusKeyManager(this.items)
        .withWrap()
        .skipPredicate(this.keyManagerSkipPredicate);
    } else {
      this.keyManager = new ActiveDescendantKeyManager(this.items)
        .withWrap()
        .skipPredicate(this.keyManagerSkipPredicate);
    }
  }

  private keyManagerSkipPredicate(item: ListItemComponent): boolean {
    return item.type === 'simple' || item.disabled;
  }

  private setItemDefaults(item: ListItemComponent, index: number) {
    // Timeout to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      item.type = item.type || this.type;
      item.standalone = this.standalone;
      item.multiple = this.multiple;
      item.index = index;
    });
  }

  private deactivateItems() {
    this.keyManager?.setActiveItem(-1);
  }

  private setActiveItemFromChildClick(event: MouseEvent) {
    const child = this.getChildItemFromTarget(event);
    if (child && child.type !== 'simple') {
      this.keyManager.setActiveItem(child.index);
      event.stopPropagation();
    }
  }

  private getChildItemFromTarget(
    event: MouseEvent
  ): ListItemComponent | undefined {
    const target = event.target as HTMLElement;
    return this.items?.find((child) =>
      child.elementRef.nativeElement.contains(target)
    );
  }
}
