import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPromptComponent } from './login-prompt.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, TranslateModule, MatTooltipModule],
  declarations: [LoginPromptComponent],
  exports: [LoginPromptComponent],
})
export class LoginPromptModule {}
