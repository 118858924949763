import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MobileLanguageComponent } from './mobile-language.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [MobileLanguageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
  ],
  providers: [],
  exports: [MobileLanguageComponent],
})
export class MobileLanguageModule {}
