import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
} from '@angular/core';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-dialog-header',
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @HostBinding() class = 'zelis-dls';

  @Input() title?: string;
  @Input() titleClass = 'heading-4 tracking-normal';
  @Input() caption?: string;
  @Input() dataCyBase?: string;
  @Input() closeButtonLabel = 'close button';
  @Input()
  @coerceBoolean
  dismissable!: boolean;

  get titleCy() {
    return this['dataCyBase'] ? `${this['dataCyBase']}-title` : '';
  }

  get captionCy() {
    return this['dataCyBase'] ? `${this['dataCyBase']}-caption` : '';
  }
  get closeCy() {
    return this['dataCyBase'] ? `${this['dataCyBase']}-close` : '';
  }
}
