import { BvaSelectComponent } from './bva.select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BvaSidebarComponent } from './sidebar/sidebar.component';
import { BvaInfoComponent } from './info/info.component';
import { BvaRecommendedProvidersComponent } from './recommended-providers/recommended-providers.component';
import { RouterModule } from '@angular/router';
import { BvaNotesComponent } from './notes/notes.component';
import { BvaIntendedProvidersComponent } from './intended-providers/intended-providers.component';
import { BvaIntendedProviderComponent } from './intended-provider/intended-provider.component';
import { PipesModule } from '@zelis/platform-ui-components';
import { FlashMessageModule } from '@zelis/platform-ui-components';
import { NavigationService } from '../../../services/navigation.service';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DlsIconModule } from '@zelis/dls/icons';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    PipesModule,
    FlashMessageModule,
    DlsProgressSpinnerModule,
    MatFormFieldModule,
    MatLegacyAutocompleteModule,
    MatInputModule,
    DlsIconModule,
    MatExpansionModule,
    MatDividerModule,
  ],
  declarations: [
    BvaSelectComponent,
    BvaSidebarComponent,
    BvaInfoComponent,
    BvaNotesComponent,
    BvaIntendedProvidersComponent,
    BvaRecommendedProvidersComponent,
    BvaNotesComponent,
    BvaIntendedProviderComponent,
  ],
  exports: [BvaSelectComponent, BvaSidebarComponent],
  providers: [NavigationService],
})
export class BvaModule {}
