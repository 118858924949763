import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogService, FullScreenState } from '../dialog.service';

export interface DialogButtonConfig {
  content: string;
  onClick?: (event: MouseEvent) => void;
  isCloseButton?: boolean;
  hasInitialFocus?: boolean;
  icon?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'zelis-dialog-footer',
  templateUrl: './dialog-footer.component.html',
})
export class DialogFooterComponent implements OnInit {
  @HostBinding() class = 'zelis-dls';

  @Input() buttons: DialogButtonConfig[] = [];
  @Input() color = 'accent';

  fullScreenState$: Observable<FullScreenState> | undefined;

  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit() {
    this.fullScreenState$ = this.dialogService.getFullScreenStateStream(
      this.dialogRef.id
    );
  }

  handleButtonClick(event: MouseEvent, index: number) {
    this.buttons[index].onClick?.(event);
  }
}
