<div mat-dialog-title>
  <div class="flex w-full justify-between relative items-baseline">
    <div>
      <p
        *ngIf="title"
        class="font-bold"
        [ngClass]="titleClass"
        [attr.data-cy]="titleCy"
        [innerHTML]="title"
      ></p>
      <p
        *ngIf="caption"
        class="heading-6 tracking-normal"
        [attr.data-cy]="captionCy"
        [innerHtml]="caption"
      ></p>
    </div>

    <button
      mat-dialog-close
      class="relative top-1"
      [aria-label]="closeButtonLabel"
      data-cy="dialog-header-close-button"
    >
      <mat-icon *ngIf="dismissable" svgIcon="dls-times-plain-light"></mat-icon>
    </button>
  </div>
</div>
