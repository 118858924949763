import { NgModule } from '@angular/core';
import { UnfocusModule } from '../../directives/unfocus/unfocus.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DlsProgressBarModule } from '@zelis/dls/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MediaMarshaller } from '@ngbracket/ngx-layout';
import { DlsCardModule } from '@zelis/dls/card';

@NgModule({
  exports: [
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatListModule,
    DlsProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    UnfocusModule,
    MatExpansionModule,
    DlsCardModule
  ],
})

// Hack for print window breakpoints bug: https://github.com/angular/flex-layout/issues/1201
// PD-5186
export class MaterialModule {
  private lastValue;

  public constructor(m: MediaMarshaller) {
    // @ts-ignore
    m.subject.subscribe((x) => {
      if (
        // @ts-ignore
        m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0
      ) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        m.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        m.hook.collectActivations = () => {};
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });
  }
}
