<div
  data-cy="network-selection-wizard-overlay"
  class="network-selection-wizard flex flex-col h-full"
  zelisTrapFocus
  autoTrap="true"
>
  <div class="header">
    <app-overlay-header
      (goToOverlayPage)="onGoToOverlayPage(initialPage)">
    </app-overlay-header>
  </div>
  <div class="wizard-body flex-grow">
    <platform-page-container
      [fullscreen]="media.isActive('lt-md')"
      [contentSpacing]="false"
    >
      <div
        [class.hidden]="this.pageTransition.page === 'initial-page'"
        class="flex items-center h-[72px]"
      >
        <zelis-button
          data-cy="network-selection-wizard-back-button"
          *ngIf="showBackButton()"
          size="large"
          variant="basic"
          [color]="'system-blue'"
          (clickFn)="onGoBack()"
        >
          <mat-icon svgIcon="dls-angle-plain-left-light"></mat-icon>
          {{"app_global_back" | translate}}
        </zelis-button>
      </div>

      <div class="body-container">
        <div class="page-transition-stage relative w-full">
          <app-location-of-care
            data-cy="network-selection-wizard-location-of-care"
            *ngIf="pageTransition.page === 'location-of-care'"
            [isDefaultLocationPage]="networkSelectWizardService.isDefaultLocationPage"
            [@slideInOutAnimation]="pageTransition.direction"
            (handleLocationSubmit)="handleLocationSubmit()"
            (handleModifyLocation)="handleModifyLocation()"
          >
          </app-location-of-care>
          <app-plan-type-selection
            data-cy="network-selection-wizard-plan-type-selection"
            *ngIf="pageTransition.page === 'plan-type-selection'"
            [@slideInOutAnimation]="pageTransition.direction"
            (marketSegment)="handleMarketSegment($event)"
          >
          </app-plan-type-selection>
          <app-product-type-selection
            data-cy="network-selection-wizard-product-type-selection"
            *ngIf="pageTransition.page === 'product-type-selection'"
            [@slideInOutAnimation]="pageTransition.direction"
            (productType)="handleProductType($event)"
          >
          </app-product-type-selection>
          <app-employer-state-selection
            data-cy="network-selection-wizard-employer-state-selection"
            *ngIf="pageTransition.page === 'Employer'"
            [@slideInOutAnimation]="pageTransition.direction"
            [planStates]="planStates"
            [selectedPlanState]="selectedPlanState"
            (planStateSelect)="handlePlanStateSelect($event)"
          >
          </app-employer-state-selection>
          <app-medicare-selection
            data-cy="network-selection-wizard-medicare-selection"
            *ngIf="pageTransition.page === 'Medicare'"
            [@slideInOutAnimation]="pageTransition.direction"
            (subMarketSegment)="handleSubMarketSegment($event)"
          >
          </app-medicare-selection>
          <app-plan-list-selection
            data-cy="network-selection-wizard-plan-list-selection"
            *ngIf="pageTransition.page === 'plan-list-selection'"
            [@slideInOutAnimation]="pageTransition.direction"
            [pageHeading]="planListTitle | translate"
            [description]="planListDescription | translate"
            [plans]="plans"
            [showAllPlans]="selectedProductType === 'ALL'"
            (closeOverlay)="closeOverlay()"
            (refreshPlans)="handleProductType($event)"
          >
          </app-plan-list-selection>
          <app-confirm-previous-selections
            data-cy="network-selection-wizard-confirm-selections"
            *ngIf="pageTransition.page === 'confirm-previous-selections'"
            [@slideInOutAnimation]="pageTransition.direction"
            (closeOverlay)="closeOverlay()"
            (goToOverlayPage)="onGoToOverlayPage($event)"
            [previousSelections]="previousSelections"
          >
          </app-confirm-previous-selections>
          <app-modify-previous-selections
            data-cy="network-selection-wizard-modify-selections"
            *ngIf="pageTransition.page === 'modify-previous-selections'"
            [previousSelections]="previousSelections"
            [@slideInOutAnimation]="pageTransition.direction"
            (goToOverlayPage)="onGoToOverlayPage($event)"
          >
          </app-modify-previous-selections>
        </div>
      </div>
    </platform-page-container>
  </div>
</div>
