import { FocusableOption, Highlightable } from '@angular/cdk/a11y';
import {
  Component,
  ViewChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  HostListener,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { MatRadioButton } from '@angular/material/radio';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import type { IconName } from '@zelis/dls/icons';
import type { LozengeEmphasis } from '@zelis/dls/lozenge';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent
  implements OnChanges, Highlightable, FocusableOption
{
  @HostBinding('class') get hostClasses() {
    return {
      'zelis-dls': true,
      block: true,
      'outline-none': true,
      'hover:bg-palette-m3-system-gray-95': this.type !== 'simple',
      'focus:bg-palette-m3-system-gray-95': this.type !== 'simple',
      'bg-palette-m3-system-gray-95': this.isActive,
      'cursor-pointer': ['selection', 'navigation', 'action'].includes(
        this.type
      ),
    };
  }

  @HostBinding('attr.tabindex') get hostTabindex() {
    return this.type === 'simple' || !this.standalone ? -1 : 0;
  }

  @HostBinding('attr.role') get hostRole() {
    return this.ariaRole[this.type];
  }

  @HostBinding('attr.aria-selected') get hostAriaSelected() {
    return this.isActive ? 'true' : 'false';
  }

  @HostBinding('attr.aria-disabled') get hostAriaDisabled() {
    return this.disabled ? 'true' : 'false';
  }

  @Input() type!: 'simple' | 'selection' | 'navigation' | 'action';
  @Input() index = 0;
  @Input() primaryText!: string;
  @Input() secondaryText?: string;
  @Input() leftIconName?: IconName;
  @Input() rightIconName?: IconName;
  @Input() avatarSrc?: string;
  @Input() avatarPlaceholderIcon!: IconName;
  @Input() isProviderAvatar?: boolean;
  @Input() metadata?: string;
  @Input() lozengeText?: string;
  @Input() lozengeType?: 'theme' | 'low-emphasis-gray' | 'high-emphasis-yellow';
  @Input() lozengeColor?: string;
  @Input() lozengeEmphasis?: LozengeEmphasis;
  @Input() checkboxPosition: 'before' | 'after' = 'after';
  @Input() color: any = 'primary';
  @Input() @coerceBoolean selected?: boolean;
  @Input() highlightTerm = '';
  @Input() href = '';
  @Input() forwardedRouterLink = '';
  @Input() fragment = '';
  @Input() target = '_self';
  @Input() name = 'radioGroup';
  @Input() id!: any;
  @Input() value?: any;
  @Input() rightIconTooltipText!: string;
  @Input() rightIconTooltipPosition!: TooltipPosition;
  @Input() rightIconClass?: string;
  @Input() linkClass?: string;
  @Input() ariaLabel? = 'Icon';
  @Input() lozengeClasses?: string;
  @Input() @coerceBoolean denseLeadingIcon?: boolean;
  @Input() @coerceBoolean disabled = false;
  @Input() @coerceBoolean slideToggle?: boolean;
  @Input() @coerceBoolean leadingSlideToggle?: boolean;
  @Input() @coerceBoolean isActive?: boolean;
  @Input() @coerceBoolean multiple = true;
  @Input() @coerceBoolean standalone = true;

  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() clickFn: EventEmitter<Event> = new EventEmitter();
  @Output() itemSelectionChange: EventEmitter<ListItemComponent> =
    new EventEmitter();

  @ViewChild(MatCheckbox)
  private checkBoxRef!: MatCheckbox;

  @ViewChild(MatRadioButton)
  private radioButtonRef!: MatRadioButton;

  @ViewChild(MatSlideToggle)
  private slideToggleRef!: MatSlideToggle;

  public ariaRole = {
    simple: 'listitem',
    selection: 'option',
    navigation: 'navigation',
    action: 'button',
  };

  @HostListener('click') onHostClick(event: Event) {
    this.handleItemSelect(event);
  }

  constructor(
    public elementRef: ElementRef,
    private window: Window,
    private router: Router
  ) {}

  ngOnChanges() {
    this.validateInputs();
  }

  setActiveStyles() {
    if (this.type !== 'simple') {
      this.isActive = true;
    }
  }

  setInactiveStyles() {
    this.isActive = false;
  }

  getLabel(): string {
    return this.primaryText;
  }

  focus() {
    this.elementRef.nativeElement.focus();
  }

  public getChecked(): boolean {
    if (this.type !== 'selection') {
      return false;
    }
    if (this.slideToggle) {
      return this.slideToggleRef.checked;
    }
    if (this.multiple) {
      return this.checkBoxRef.checked;
    } else {
      return this.radioButtonRef.checked;
    }
  }

  public handleItemSelect(event: Event) {
    if (this.disabled) {
      return;
    }
    if (this.type === 'selection') {
      this.onSelectItemSelect();
      this.itemSelectionChange.emit(this);
    }
    if (this.type === 'navigation') {
      this.onNavListItemSelect(event);
    }
    if (this.type === 'action') {
      this.onActionListItemSelect(event);
    }
  }

  public onSelectItemSelect() {
    if (this.type !== 'selection') {
      return;
    }
    if (this.slideToggle) {
      this.slideToggleRef.toggle();
      return;
    }
    if (this.multiple) {
      this.checkBoxRef.toggle();
    } else {
      this.radioButtonRef.checked = true;
    }
  }

  public onNavListItemSelect(event: Event) {
    if (this.type !== 'navigation') {
      return;
    }
    this.clickFn.emit(event);
    if (this.href) {
      this.window.open(this.href, this.target);
      return;
    }
    if (this.forwardedRouterLink) {
      let options: undefined | object = undefined;
      if (this.fragment !== '') {
        options = { queryParamsHandling: 'merge', fragment: this.fragment };
      }
      this.router.navigate([this.forwardedRouterLink], options);
      return;
    }
  }

  public onActionListItemSelect(event: Event) {
    if (this.type !== 'action') {
      return;
    }
    this.clickFn.emit(event);
  }

  private validateInputs() {
    if (this.type === 'navigation' && this.href && this.forwardedRouterLink) {
      throw new Error('Navigation Item: Cannot have both href and routerLink');
    }
    if (
      this.type === 'selection' &&
      this.slideToggle &&
      this.value !== undefined
    ) {
      throw new Error('Selection Item: Cannot have both slideToggle and value');
    }
    if (
      this.type === 'selection' &&
      this.multiple === false &&
      this.value === undefined &&
      this.name === undefined
    ) {
      throw new Error(
        'Selection Item: Single selection items must also have value and name set'
      );
    }
  }
}
