import {
  Component,
  OnChanges,
  SimpleChanges,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { Place } from '@classes/place.class';
import { LocationService } from '@services/location/location.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LocationAutosuggest } from '@classes/location-autosuggest.class';
import { CcssService } from '@services/ccss/ccss.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { NetworkSelectionWizardService } from "@services/network-selection-wizard/network-selection-wizard.service";

@Component({
  selector: 'app-global-location-select',
  styleUrls: ['./global-location-select.component.scss'],
  templateUrl: './global-location-select.component.html',
})
export class GlobalLocationSelectComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() results: any;
  @Input() selectedOption: Place;
  @Input() mobileOverlay: boolean;
  @Input() appearance: string = 'outline';
  @Input() isWizardExperience?: boolean;
  @Input() darkTheme?: boolean = false;

  @Output() termSelected = new EventEmitter();
  @Output() termEntered = new EventEmitter();
  @Output() emitBrowserLocationRequested = new EventEmitter();
  @Output() optionSelected: EventEmitter<Place | string> = new EventEmitter();

  public mappedResults: Place[] = null;
  public auto: MatAutocomplete;
  public text: string;
  public item: any;
  public gpsLoading: boolean = false;
  public subscriptions = new SubscriptionManager();

  private userSelectedPlace: Place = this.getStoredLocation();

  constructor(
    private locationService: LocationService,
    private locationAutosuggest: LocationAutosuggest,
    private ccssService: CcssService,
    private changeDetector: ChangeDetectorRef,
    private storage: StorageUtilities,
    private networkSelectWizardService: NetworkSelectionWizardService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.resolveSelectedLocation());
    this.subscriptions.add(this.getGpsStatus());
    this.applyMobileTrayStyling();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['results'] && changes['results'].currentValue) {
      this.mappedResults = changes['results'].currentValue;
    }
    if (
      changes &&
      changes['selectedOption'] &&
      changes['selectedOption'].currentValue
    ) {
      this.selectedOption = changes['selectedOption'].currentValue;
      if (this.matchesSelectedOptionGeo()) {
        this.selectedOption = new Place(this.userSelectedPlace);
      }
      this.setAutosuggestText();
    }
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.removeMobileTrayStyling();
    this.subscriptions.destroy();
  }

  public onInputChange() {
    if (this.text && this.text.length >= 2) {
      this.termEntered.emit({ termEntered: this.text });
    }
  }

  /**
   * handleSelect
   * @param event: string | Place
   * @param emit: boolean = true. Only emit when triggered by user selection.
   */
  public handleSelect(event: string | Place, emit: boolean = true) {
    if (event === 'current-location') {
      this.text = null;
      this.requestBrowserLocation();
    } else {
      event = new Place(event);
      this.userSelectedPlace = event;
      this.storage.sessionStorageSet('userSelectedPlace', event);
      if (emit) {
        this.termSelected.emit(event);
      }
    }
    if (emit) {
      this.optionSelected.emit(event);
    }
  }

  public requestBrowserLocation() {
    this.emitBrowserLocationRequested.emit();
    setTimeout(() => {
      if (this.matAutocompleteTrigger) {
        this.matAutocompleteTrigger.closePanel();
      }
    });
  }

  public setAutosuggestText() {
    const isReturnWizardUser = this.networkSelectWizardService.isReturnUser;
    
    if (this.isWizardExperience && isReturnWizardUser) {
      this.setWizardDefault();
      return;
    }
    
    if (this.selectedOption && this.selectedOption.city) {
      this.text = this.selectedOption.toString();
    }
    
    if (this.text === 'current-location') {
      this.text = null;
    }
  }

  public onKeyUp(): void {
    this.changeDetector.detectChanges();
  }
  
  private setWizardDefault() {
    const savedLocation = this.networkSelectWizardService.getSavedLocation();
    this.text = new Place(savedLocation).toString();
  }

  private resolveSelectedLocation(): Subscription {
    return this.locationService.geo.subscribe((location) => this.initUserSelectedLocation(location));
  }

  private initUserSelectedLocation(resolvedLocation: Place): void {
    if (this.userSelectedPlace?.geo !== resolvedLocation.geo) {
      this.userSelectedPlace = resolvedLocation;
    }
    if (this.userSelectedPlace) {
      this.selectedOption = new Place(this.userSelectedPlace);
      this.setAutosuggestText();
      this.handleSelect(this.selectedOption, false);
    }
  }

  private applyMobileTrayStyling(): void {
    if (this.mobileOverlay) {
      this.ccssService.applyClassToBody('location-fullscreen-overlay');
    }
  }

  private removeMobileTrayStyling(): void {
    if (this.mobileOverlay) {
      this.ccssService.removeBodyClass('location-fullscreen-overlay');
    }
  }

  private matchesSelectedOptionGeo(): boolean {
    return (
      this.userSelectedPlace?.isValid() &&
      this.selectedOption?.isValid() &&
      this.selectedOption.geo === this.userSelectedPlace.geo
    );
  }

  private getGpsStatus(): Subscription {
    return this.locationAutosuggest.gpsStatus
      .pipe(filter((status) => !!status))
      .subscribe((status) => {
        this.gpsLoading = status.loading;
      });
  }

  private getStoredLocation(): Place {
    const userSelectedPlace = new Place(this.storage.sessionStorageGet('userSelectedPlace', {}));
    const userSelectedCritical: any[] = this.storage.localStorageGet('userSelectedCritical', []) || [];
    const userSelectedCriticalPlace = new Place(userSelectedCritical[userSelectedCritical.length - 1]?.location || {});

    if (
      userSelectedPlace?.isValid() &&
      userSelectedCriticalPlace.isValid() &&
      userSelectedPlace?.geo === userSelectedCriticalPlace?.geo
    ) {
      return userSelectedPlace;
    }

    if (userSelectedCriticalPlace?.isValid()) {
      return userSelectedCriticalPlace;
    }

    return null;
  }
}
