<div
  class="overlay-header"
  [ngStyle]="{ 'border-bottom': '8px rgb(var(--palette-primary-500)) solid' }"
>
  <platform-page-container
    [fullscreen]="media.isActive('lt-md')"
    [contentSpacing]="false"
  >
    <div class="header-container py-2 px-6 md:py-4 md:px-0 flex md:justify-between">
      <div class="flex items-center md:hidden">
        <app-mobile-hamburger-menu></app-mobile-hamburger-menu>
      </div>
      <div class="flex ml-6 md:ml-0">
        <a
          href=""
          (click)="goToOverlayPage.emit({ page: 'home', direction: 'back' })"
          eventPreventDefault
        >
          <img
            class="w-auto max-w-full max-h-[40px]"
            *ngIf="(headerLogo | async)?.header?.primary_logo as logo"
            [ngClass]="ccss.getClass('logo_img')"
            [attr.alt]="'app_return_home' | translate"
            [src]="logo"
          />
        </a>
      </div>
      <div
        class="text-right text-gray-600 hidden w-full h-full md:flex md:w-fit"
        *ngIf="(suppressLanguageSelect | async) !== true"
      >
        <app-global-language-select></app-global-language-select>
      </div>
    </div>
  </platform-page-container>
</div>
