import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveForPrintComponent } from './save-for-print.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DlsSnackbarModule } from '@zelis/dls/snackbar';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [
    SaveForPrintComponent,
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    DlsSnackbarModule,
    TranslateModule,
    MatTooltipModule,
  ],
  providers: [
    DlsSnackbarModule,
  ],
  exports: [SaveForPrintComponent, DlsSnackbarModule]
})
export class SaveForPrintModule { }
